import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosRequestConfig} from "axios";
import instance from "config/axios.config"
import {createBrowserHistory} from "history";
import {BASE_URL} from "../../config/utils";

export const signIn = createAsyncThunk(
    'signIn',
    async (params: { username?: string, password?: string }, {rejectWithValue}) => {

        try {
            const isHasToken = localStorage.getItem("access_token");

            let url = `${BASE_URL}/api/authenticate`;

            const options: AxiosRequestConfig = {
                url, method: 'POST', data: {
                    login: params.username,
                    password: params.password
                }
            }

            const response = await instance(options);

            if (response?.status !== 200) return rejectWithValue(new Error("Authorization error!"))

            if (!isHasToken) {
                localStorage.setItem('access_token', response.data.idToken);
                createBrowserHistory().push('/')
            }

        } catch (error: any) {

            return rejectWithValue(error?.response);

        }
    }
)

export const signOut = async () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
}
