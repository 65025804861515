import "../styles.css";
import {Link, useNavigate} from "react-router-dom";
import {signOut} from "../../../pages/login/service.request";

type PropsTypeCHeader = {
    backButton?: any
}

const CHeader = ({backButton}: PropsTypeCHeader) => {
    const navigate = useNavigate()
    const loggedIn = Boolean(localStorage.getItem('access_token'))
    const handleLogOut = () => {
        if (!loggedIn) {
            return navigate('/signin')
        }
        signOut()
        navigate('/signin')
    }
    return (
        <nav className="bg-[#0000FF] sticky top-0 w-[100%] z-10 py-2">
            <div className="mx-auto sm:px-6 lg:px-5">
                <div className="flex h-13 items-center justify-between">
                    <div className="flex items-center">
                        <div className="flex-shrink-0 flex flex-col">
                            <span
                                className="text-white text-[28px] font-medium tracking-wide">Toshkent moliya instituti</span>
                            <span
                                className="text-[#ffffff] text-[10px] font-thin">@created by RTTM</span>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-4 flex items-end md:ml-6">
                            {backButton && <div className="relative">
                                <Link to='/'
                                      className="bg-[#ffffff] block w-[100px] text-black rounded-md px-3 py-2 mx-3 text-center text-md font-medium"
                                      aria-current="page">Orqaga</Link>
                            </div>
                            }
                            <div className="relative">
                                <button
                                    onClick={handleLogOut}
                                    className="bg-[#ffffff] block w-[100px] text-black rounded-md px-3 py-2 text-center text-md font-medium"
                                    aria-current="page">{loggedIn ? 'Chiqish' : 'Kirish'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default CHeader;
