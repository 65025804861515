import {Form, Input} from 'antd';
import "./styles.css";
import {useAppDispatch} from './../../store/service';
import {signIn} from './service.request';
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Login = () => {
    const navigate = useNavigate()
    const dispatch: any = useAppDispatch();
    const onFinish = async (values: { username: string, password: string }) => {
        const x = await dispatch(signIn({username: values.username, password: values.password}))
        if (x.type === "signIn/fulfilled") {
            navigate('/')
        }
    };
    useEffect(()=>{
        if (localStorage.getItem('access_token')){
            navigate('/')
        }
    })

    return (
        <div>
            <section className="relative isolate overflow-hidden bg-white py-24 px-6 sm:py-32 lg:px-8 h-[100vh]">
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <figure className="flex justify-center">
                        <section className="py-10 px-5 min-h-[400px] w-[700px] bg-slate-100 rounded-xl">
                            <div className="flex justify-center">
                                <div className="h-full w-[400px] items-center justify-center">
                                    <div className="flex justify-center">
                                        <img src="logo1.png" alt=""/>
                                    </div>
                                    <Form
                                        layout='vertical'
                                        onFinish={onFinish}
                                        className="login-box"
                                    >
                                        <div className="mt-10">
                                            <Form.Item label="Username" name="username" className='w-[100%]'>
                                                <Input/>
                                            </Form.Item>
                                            <Form.Item label="Password" name="password">
                                                <Input.Password/>
                                            </Form.Item>
                                            <div className="text-end">
                                                <button
                                                    type="submit"
                                                    className="inline-block rounded w-[130px] bg-[#0000ff] active:bg-[#033B2F] py-2 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                                                    data-te-ripple-init
                                                    data-te-ripple-color="light">
                                                    Login
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </section>
                    </figure>
                </div>
            </section>
        </div>
    )
}
export default Login;
