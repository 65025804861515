import axios from "axios";
import instance from "config/axios.config"
import {BASE_URL} from "../../config/utils";

export const getCertificates = async () => {
    const token = localStorage.getItem("access_token");
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.baseURL = BASE_URL
    const response = await axios({ url: `${BASE_URL}/api/guvohnoma/showAll`, method: 'GET' });
    // const response = await instance({ url: '/guvohnoma/showAll', method: 'GET' });
    return response.data
}


export const getCertificate = async (id: number) => {
    const response = await instance({ url: `/api/guvohnoma/showPerson/${id}`, method: 'GET' });
    return response.data
}

export const getCertificate2 = async (id: number) => {
    const response = await instance({ url: `/public/certificate/${id}`, method: 'GET' });
    return response.data
}