import { createSlice } from "@reduxjs/toolkit";
import { signIn } from "pages/login/service.request";


export interface IAuth {
    isLoading: boolean,
    status: 'pending' | 'success' | 'error',
    isAuthenticated: boolean
}

const initialState: IAuth = {
    isLoading: true,
    status: 'success',
    isAuthenticated: false
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signOut(state) {
            state.isAuthenticated = false;
            state.status = 'success';
        },
    },
    extraReducers: (building) => {
        building
            .addCase(signIn.pending, (state) => {
                state.isLoading = true;
                state.status = 'pending'
            })
            .addCase(signIn.fulfilled, (state) => {

                state.isAuthenticated = true;
                state.isLoading = false;

            })
            .addCase(signIn.rejected, (state, action) => {
                state = {
                    ...state,
                    isLoading: false,
                    status: 'error'
                }
            })
    }
})


export const AUTH_ACTIONS = authSlice.actions;

export default authSlice;
