import {useQuery} from "@tanstack/react-query";
import {Button, Form, Modal, Spin, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import axios from "axios";
import instance from "config/axios.config";
import moment from "moment";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import CertificateFormUi from "./form_ui";
import {getCertificates} from "./service.request";
import PrintIcon from "../../assets/icons/printIcon";
import EditIcon from "../../assets/icons/editIcon";
import DeleteIcon from "../../assets/icons/deleteIcon";
import {BASE_URL} from "../../config/utils";

type TypeValues = {
    listNumber: string,
    orderNumber: string,
    date: string,
    author: string,
    speciality: string,
    nameAndTypeOfEducationalLiterature: string
}

const Dashboard = () => {

    const [form] = Form.useForm()
    const [formEdit] = Form.useForm()
    const navigate = useNavigate()

    const [editId, setEditId] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = React.useState(false);

    useEffect(()=>{
        if (!localStorage.getItem('access_token')){
            navigate('/signin')
        }
    }, [])

    const certificates = useQuery({
        queryKey: ['certificates'],
        queryFn: () => getCertificates()
    });
    //@ts-ignore
    const rdata = Array.from(certificates?.data || [])?.sort((a: any, b: any) => b.id - a.id).map(e=>({...e, key:e.id}))

    const deleteItem = async (id: number) => {
        if (window.confirm("O'chirasizmi?")) {
            await axios({
                url: `${BASE_URL}/api/guvohnoma/delete/${id}`,
                method: "Delete",
                headers: {"Authorization": `Bearer ${localStorage.getItem('access_token')}`}
            })
            certificates.refetch()
        }
    }
    const editItem = async (e: any) => {
        setEditId(e.id)
        setIsModalOpenEdit(true)
        formEdit.setFieldValue('listNumber', e.listNumber)
        formEdit.setFieldValue('date', moment(`${e.year}-${e.month}-${e.day}`))
        formEdit.setFieldValue('orderNumber', e.orderNumber)
        formEdit.setFieldValue('author', e.author)
        formEdit.setFieldValue('speciality', e.speciality)
        formEdit.setFieldValue('nameAndTypeOfEducationalLiterature', e.nameAndTypeOfEducationalLiterature)
        certificates.refetch()
    }

    const columns: ColumnsType<any> = [
        {
            title: <span className="text-[#5F6368]">Muallif</span>,
            dataIndex: 'author',
        },
        {
            title: <span>Mutaxasisligi</span>,
            dataIndex: 'speciality',
        },
        {
            title: <span>Adabiyot nomi</span>,
            dataIndex: 'nameAndTypeOfEducationalLiterature',
        },
        {
            title: <span className="text-[13px] block text-center">Raqami</span>,
            dataIndex: 'orderNumber',
            width: 100,
        },
        {
            title: <span className="w-[100px]">Sertifikat</span>,
            render: (e) => <div style={{display: 'flex'}}>
                <Button onClick={() => navigate(`/certificate/${e?.id}`)}>
                    <PrintIcon/>
                </Button>
                <Button onClick={() => editItem(e)}>
                    <EditIcon/>
                </Button>
                <Button onClick={() => deleteItem(e?.id)}>
                    <DeleteIcon/>
                </Button>
            </div>,
            width: 100,
            align: 'center',
        },
    ];

    const handleOk = () => setIsModalOpen(false);

    const handleCancel = () => setIsModalOpen(false);

    const onSubmit = async (values: TypeValues) => {
        try {
            const data = {
                year: parseInt(JSON.stringify(values.date).substring(1, 5)).toString(),
                month: parseInt(JSON.stringify(values.date).substring(6, 8)).toString(),
                day: parseInt(JSON.stringify(values.date).substring(9, 11)).toString(),
                orderNumber: values.orderNumber,
                listNumber: values.listNumber,
                author: values.author,
                speciality: values.speciality,
                nameAndTypeOfEducationalLiterature: values.nameAndTypeOfEducationalLiterature
            }
            await instance({
                url: `${BASE_URL}/api/guvohnoma/create_certificate`,
                method: "POST",
                data
            })
            setIsModalOpen(false)
            certificates.refetch()
        } catch (error) {
        }
    }
    const onSubmitEdit = async (values: any) => {
        try {
            const data = {
                year: parseInt(JSON.stringify(values.date).substring(1, 5)).toString(),
                month: parseInt(JSON.stringify(values.date).substring(6, 8)).toString(),
                day: parseInt(JSON.stringify(values.date).substring(9, 11)).toString(),
                orderNumber: values.orderNumber,
                listNumber: values.listNumber,
                author: values.author,
                speciality: values.speciality,
                nameAndTypeOfEducationalLiterature: values.nameAndTypeOfEducationalLiterature
            }
            await instance({
                url: `${BASE_URL}/api/guvohnoma/create_certificate/${editId}`,
                method: "PUT",
                data
            })
            setIsModalOpenEdit(false)
            certificates.refetch()
        } catch (error) {
        }
    }

    return (
        <div className="bg-white rounded-md px-2 py-3">

            <Modal
                title="Sertifikat yaratish"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onSubmit}
                >
                    <CertificateFormUi/>
                    <div className="mt-4 text-end">
                        <Button type="primary" danger className="bg-[red] mr-2 w-[130px]"
                                onClick={() => form.resetFields()}>Reset</Button>
                        <Button type="primary" htmlType="submit" className="bg-[blue] w-[130px]">Submit</Button>
                    </div>
                </Form>
            </Modal>
            <Modal
                title="Sertifikat edit"
                open={isModalOpenEdit}
                onOk={handleOk}
                onCancel={() => setIsModalOpenEdit(false)}
                footer={null}
            >
                <Form
                    layout="vertical"
                    form={formEdit}
                    onFinish={onSubmitEdit}
                >
                    <CertificateFormUi/>
                    <div className="mt-4 text-end">
                        <Button type="primary" danger className="bg-[red] mr-2 w-[130px]"
                                onClick={() => form.resetFields()}>Reset</Button>
                        <Button type="primary" htmlType="submit" className="bg-[blue] w-[130px]">Submit</Button>
                    </div>
                </Form>
            </Modal>

            <div>
                <div className="flex justify-between mb-2">
                    <h6 className="text-[#5F6368] text-xl">Sertifikatlar</h6>
                    <Button type="primary" className="bg-[blue]" onClick={() => setIsModalOpen(!isModalOpen)}>
                        Yaratish +
                    </Button>
                </div>
                <Spin spinning={certificates.isLoading}>
                    <Table
                        columns={columns}
                        dataSource={rdata}
                        size="small"
                        bordered
                        pagination={{pageSize: 10}}
                    />
                </Spin>
            </div>
        </div>
    )
}


export default Dashboard;
