import { Col, DatePicker, Form, Input, Row } from "antd";




const CertificateFormUi = () => {




    return (
        <Row gutter={[12, 12]}>
            <Col xl={24}>
                <Form.Item className="my-0" label="Author" name="author">
                    <Input />
                </Form.Item>
            </Col>
            <Col xl={24}>
                <Form.Item className="my-0" label="Speciality" name="speciality">
                    <Input />
                </Form.Item>
            </Col>
            <Col xl={24}>
                <Form.Item className="my-0" label="Date" name="date">
                    <DatePicker className="w-[100%]" />
                </Form.Item>
            </Col>
            <Col xl={24}>
                <Form.Item className="my-0" label="Order number" name="orderNumber">
                    <Input />
                </Form.Item>
            </Col>
            <Col xl={24}>
                <Form.Item className="my-0" label="Type Of Education Literature" name="nameAndTypeOfEducationalLiterature">
                    <Input />
                </Form.Item>
            </Col>
            <Col xl={24}>
                <Form.Item className="my-0" label="List number" name="listNumber">
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    )

}


export default CertificateFormUi;