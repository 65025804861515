import axios, {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {BASE_URL} from '../utils';
import {ResponseError} from './errors';

let instance = axios.create();
const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {

    config.baseURL = BASE_URL;

    // if (!config.url?.includes('authenticate')) {
    // config.headers.Authorization = `Bearer ${token}`
    // config.headers.setAuthorization(`Bearer ${token}`)
    // }

    // config.headers.set('Authorization', `Bearer ${token}`);
    // config.headers!['Content-Type'] = 'application/json';
    // config.headers!['Access-Control-Allow-Origin'] = '*';
    // config.headers!['Access-Control-Allow-Credentials'] = true;
    // config.withCredentials = true
    // config.headers!['Access-Control-Allow-Origin'] = true;

    return config;
}

const onRequestError = async (error: AxiosError): Promise<AxiosError> => {
    new ResponseError(error);
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    new ResponseError(error);
    return Promise.reject(error);
}

instance.interceptors.request.use(onRequest, onRequestError)
instance.interceptors.response.use(onResponse, onResponseError)

export default instance;
