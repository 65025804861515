import React from 'react';
// import {Document, Font, Image, Page, StyleSheet, div, View} from '@react-pdf/renderer';
import page1 from 'assets/images/certificte1.jpg'
import page2 from 'assets/images/Сertificate2.jpg'
import {useQrEncode} from 'react-qr-hooks';

import {BASE_URL_FRONT} from "../../config/utils";

interface PropsType {
    data: any
}

const months = ['yanvar', 'fevral', 'mart', 'aprel', 'may', 'iyun', 'iyul', 'avgust', 'sentyabr', 'oktyabr', 'noyabr', 'dekabr']

const PdfView = ({data}: PropsType) => {
    const encoded = useQrEncode(`${BASE_URL_FRONT}/sertifikat/${data?.id}`);
    const {
        year,
        day,
        month,
        orderNumber,
        author,
        speciality,
        nameAndTypeOfEducationalLiterature,
        listNumber,
    } = data

    return (
        // @ts-ignore
        <div className='document'>
            <div className='a5page'>
                <img src={page1} alt=''/>

            </div>
            <div className='a5page'>
                <img src={page2} alt=''/>
                <div>
                    <div className='text3' style={{top: '28.5vw', left: '65.47vw', width: '3.92vw'}}>{year?.substring(2)}</div>
                    <div className='text3' style={{top: '31vw', left: '20.7vw', width: '3.92vw'}}>{day}</div>
                    <div className='text3' style={{top: '31vw', left: '25.22vw', width: '12.92vw'}}>{months[Number(month) - 1]}</div>
                    <div className='text3' style={{top: '31vw', left: '45.93vw', width: '5.71vw'}}>{orderNumber}</div>
                    <div className='text3' style={{top: '36vw', left: '17.37vw', width: '64.73vw', lineHeight: 1.8}}>{author}</div>
                    <div className='text3' style={{
                        top: '47.3vw',
                        left: '17.37vw',
                        width: '64.73vw',
                        lineHeight: 1.8,
                        textAlign: speciality?.length > 60 ? 'left' : "center"
                    }}>{speciality}</div>
                    <div className='text3' style={{
                        top: '67.4vw',
                        left: '17.37vw',
                        width: '64.73vw',
                        lineHeight: 2.1
                    }}>{nameAndTypeOfEducationalLiterature}</div>
                    <div className='text3' style={{top: '116vw', left: '46.88vw', width: '15.94vw'}}>{listNumber}</div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        overflow: 'hidden',
                        width: '12.85vw',
                        height: '12.85vw',
                        left: '66.64vw',
                        top: '106.86vw'
                    }}
                >
                    <img
                        style={{
                            position: 'absolute',
                            width: '14.28vw',
                            // height: '14.28vw',
                            // left: '-0.71vw',
                            // top: '-0.71vw'
                        }}
                        alt=''
                        // @ts-ignore
                        src={encoded}
                    />
                </div>
            </div>
        </div>)

}

export default PdfView
