import {ReactElement} from 'react';
import CHeader from './Header';
import "./styles.css";

type PropsTypeCLayout = {
    children: ReactElement,
    backButton?: any
}

const CLayout = ({children, backButton}: PropsTypeCLayout) => {
    return (
        <div className="min-h-full" style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <CHeader backButton={backButton}/>
            <main className='bg-slate-200' style={{flexGrow: 1}}>
                {children}
            </main>
        </div>

    );
};

export default CLayout;
