import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import store from 'store';
import "assets/styles/index.scss"
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import './assets/styles/_font.css'
import './assets/styles/index.css'
import './index.css';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

root.render(
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
);
