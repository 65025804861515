import React from 'react';
import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import page1 from 'assets/images/certificte1.jpg'
import page2 from 'assets/images/Сertificate2.jpg'
import {useQrEncode} from 'react-qr-hooks';

// @ts-ignore
import font from 'assets/fonts/Kalam-Regular.ttf'
import {BASE_URL_FRONT} from "../../config/utils";

Font.register({family: 'CustomFont', src: font});

const styles = StyleSheet.create({
    text: {
        fontFamily: 'CustomFont',
        position: 'absolute',
        fontSize: 13,
        textAlign: 'center',
        color: '#000055',
        left: 0,
    }
});

interface PropsType {
    data: any
}

const months = ['yanvar', 'fevral', 'mart', 'aprel', 'may', 'iyun', 'iyul', 'avgust', 'sentyabr', 'oktyabr', 'noyabr', 'dekabr']

// const MyDocument = () => {
const MyDocument = ({data}: PropsType) => {
    const encoded = useQrEncode(`${BASE_URL_FRONT}/sertifikat/${data?.id}`);
    const {
        year,
        day,
        month,
        orderNumber,
        author,
        speciality,
        nameAndTypeOfEducationalLiterature,
        listNumber,
    } = data

    return (
        // @ts-ignore
        <Document renderMode="svg" >
            <Page size="A5">
                <Image
                    fixed={true}
                    src={page1}
                    style={{width: '100%', height: '100%', position: 'absolute', top: 0}}
                />

            </Page>
            <Page size="A5">
                <Image
                    fixed={true}
                    src={page2}
                    style={{width: '100%', height: '100%', position: 'absolute', top: 0}}
                />
                <View>
                    <Text style={{...styles.text, top: 122, left: 275, width: 17}}>{year?.substring(2)}</Text>
                    <Text style={{...styles.text, top: 133, left: 87, width: 17}}>{day}</Text>
                    <Text style={{...styles.text, top: 133, left: 106, width: 56}}>{months[Number(month) - 1]}</Text>
                    <Text style={{...styles.text, top: 133, left: 193, width: 24}}>{orderNumber}</Text>
                    <Text style={{...styles.text, top: 156, left: 73, width: 272, lineHeight: 1.8}}>{author}</Text>
                    <Text style={{
                        ...styles.text,
                        top: 203,
                        left: 73,
                        width: 272,
                        lineHeight: 1.8,
                        textAlign: speciality?.length > 60 ? 'left' : "center"
                    }}>{speciality}</Text>
                    <Text style={{
                        ...styles.text,
                        top: 292,
                        left: 73,
                        width: 272,
                        lineHeight: 2.0
                    }}>{nameAndTypeOfEducationalLiterature}</Text>
                    <Text style={{...styles.text, top: 490, left: 197, width: 67}}>{listNumber}</Text>
                </View>
                <View
                    style={{
                        position: 'absolute',
                        overflow: 'hidden',
                        width: 54,
                        height: 54,
                        left: 280,
                        top: 449
                    }}
                >
                    <Image
                        style={{
                            position: 'absolute',
                            width: 60,
                            height: 60,
                            left: -3,
                            top: -3
                        }}
                        // @ts-ignore
                        src={encoded}
                    />
                </View>
            </Page>
        </Document>)

}

export default MyDocument
