import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "pages/dashboard";
import Login from "pages/login";
import Certificate from "pages/dashboard/certificate";
import CLayout from "components/Dashboard/Layout";

function App() {
    return (
            <Routes>
                <Route path="/" element={<CLayout><Dashboard/></CLayout>}/>
                <Route path="/signin" element={<Login/>}/>
                <Route path="/sertifikat/:id" element={<Certificate/>}/>
                <Route path="/certificate/:id" element={<CLayout backButton={true}><Certificate/></CLayout>}/>
                <Route path="*" element={<Navigate to='/'/>}/>
            </Routes>
     );
}

export default App;
