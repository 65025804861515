import React from 'react';
import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import MyDocument from "./pdf";
import {useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getCertificate} from "./service.request";
import {isBrowser} from 'react-device-detect';
import PdfView from './PdfView';

const App = () => {
    const {id} = useParams()

    const certificate = useQuery({
        queryKey: ['certificates', id],
        queryFn: () => getCertificate(Number(id)),
    });
    const data = certificate.data

    if (data?.orderNumber) {
        return (
            <div style={{display: 'flex'}} className='frame'>
                {isBrowser
                    ?
                    <PDFViewer style={{width: '100%'}}>
                        <MyDocument data={data}/>
                    </PDFViewer>
                    :
                    <>

                        <PdfView data={data}/>

                        <div style={{padding: '12px', display: 'flex', justifyContent: 'center'}}>
                            <PDFDownloadLink document={<MyDocument data={data}/>}
                                             fileName={`guvohnoma_${data.listNumber}.pdf`}>
                                {({loading}) => (
                                    loading ? 'Kuting...' : 'Yuklab olish'
                                )}
                            </PDFDownloadLink>
                        </div>
                    </>
                }
            </div>
        )
    } else return <></>

}

export default App
